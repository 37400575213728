import React from 'react'
import './style.scss'

import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

function Content(props) {
	const content = props.wysiwyg
	const image = props.img?.localFile.childImageSharp.fluid
	const image2 = props.img2?.localFile.childImageSharp.fluid
	const image3 = props.img3?.localFile.childImageSharp.fluid

	const data = useStaticQuery(graphql`
		query {
			transition: file(relativePath: { eq: "transition-yellow-bottom.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			transitionBottom: file(relativePath: { eq: "transition-blue-bottom.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			shadow: file(relativePath: { eq: "flower-shadow.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			foundation: file(relativePath: { eq: "logo-kahlofoundation2.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const transition = data.transition?.childImageSharp.fluid
	const transitionBottom = data.transitionBottom?.childImageSharp.fluid
	const shadow = data.shadow?.childImageSharp.fluid
	const foundation = data.foundation?.childImageSharp.fluid

  return (
		<>
			{ content ? 
			<section id={props.section_id} className={`content c4 grid-12 is-inview ${props.classes}`}>

				{ props.anchor ?
					<div className="dss-anchor" id={props.anchor}></div>
				: null }

				{ image ?
					<Img fluid={image}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
						className="img1"
					/>
				: null }

				<div className='content-box'>

					<Img fluid={foundation}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="logo-foundation"
						loading='eager'
					/>

					<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />

					{ image2 ?
						<Img fluid={image2}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							className="img2"
						/>
					: null }

					{ image3 ?
						<a className="btn-img" href="https://www.recorridosvirtuales.com/frida_kahlo/museo_frida_kahlo.html" target="_blank">
							<Img fluid={image3}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
								className="img3"
							/>
						</a>
					: null }
				</div>

				<div className="flower-shadow">
					<Img fluid={shadow}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="flower"
						loading='eager'
					/>
				</div>

				<div className="transition">
					<Img fluid={transition}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="transition-green"
						loading='eager'
					/>
				</div>

				<div className="transition-bottom">
					<Img fluid={transitionBottom}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="transition-green-bottom"
						loading='eager'
					/>
				</div>

			</section>
			: null }
		</>
  )
}

export default Content