import React from 'react'
import Img from 'gatsby-image'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Gallery(props) {
	const slides = props.slider
	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 2000,
		pauseOnFocus: true,
		autoplay: false,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		accessibility: false,
		fade: true,
		focusOnSelect: true,
		adaptiveHeight: true,
		centerMode: false,
		variableWidth: false
	}

	const SlidesPack = slides.map((slide, i) => {

		const image = slide.img?.localFile.childImageSharp.fixed

		return (
			<div key={`slide-${i}`} className="slide">
				{ image ?
					<Img fixed={image}
						loading='lazy'
						fadeIn={true}
					/>
				: null }
				
				<div className='text' dangerouslySetInnerHTML={{__html: slide.wysiwyg}} />
			</div>
		)
	})

	return (
		<Slider {...settings}>
			{SlidesPack}
		</Slider>
  )
}

export default Gallery