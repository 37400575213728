import React from 'react'

import './style.scss'

import SingleIMG from './SingleIMG'
import Details from './Details'

function Hero(props) {
	const slides = props.slides
	const classes = props.classes
	const section_id = props.section_id
	const min_height = props.min_height

	const SlidesMap = slides?.map((node, i) => {
		const type = node.type
		if (type === 'img') {
			return (
				<SingleIMG key={`singleimghero - ${i}`} { ...node } />
			)
		}
		return false
	})

	return (
		<section id={`${section_id ? section_id : ''}`} className={`hero is-inview x0 t ${classes ? classes : ''}`}>

			{ props.anchor ?
				<div className="dss-anchor" id={props.anchor}></div>
			: null }

			<div className={slides && slides.length > 1 ? 'hero-slider' : 'hero-single'} style={{minHeight: min_height}}>
				{SlidesMap}
			</div>

			<Details />

		</section>
  )
}

export default Hero