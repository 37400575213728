import React from 'react'
import './style.scss'

import Img from 'gatsby-image'
import GMap from '../../../Map/GMap'
import { useStaticQuery, graphql } from 'gatsby'

function Map(props) {

	const content = props.wysiwyg
	const image = props.img?.localFile.childImageSharp.fluid

	const data = useStaticQuery(graphql`
		query {
			transition: file(relativePath: { eq: "transition-khaki.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			shadow: file(relativePath: { eq: "flower-shadow.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			garden: file(relativePath: { eq: "logo-gardenoffrida.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			corpo: file(relativePath: { eq: "logo-kahlocorporation.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			corporound: file(relativePath: { eq: "logo-kahlocorporation-round.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const transition = data.transition?.childImageSharp.fluid

  return (
		<>
			{ content ? 
			<section id={props.section_id} className={`content is-inview x1 grid-12 ${props.classes}`}>

				{ props.anchor ?
					<div className="dss-anchor" id={props.anchor}></div>
				: null }

				<div className="map-wrap">
					<div id="map" className="map" style={{width: '100%', height: '100%'}}>
						<GMap { ...props.map } />
					</div>
				</div>

				<div className='content-box'>
					<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
				</div>

				<div className="transition">
					<Img fluid={transition}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="transition-green"
						loading='eager'
					/>
				</div>

			</section>
			: null }
		</>
  )
}

export default Map