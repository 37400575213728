import React, { useState, useContext } from 'react'
import './style.scss'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faAirbnb } from '@fortawesome/free-brands-svg-icons'

import Modal from '../Modal'
import ModalContext from '../../context/ModalContext'
import CF7RequestQuote from '../Form/CF7/RequestQuote'

function Footer(props) {
	const image = props.image
	const modalContext = useContext(ModalContext)

	const data = useStaticQuery(graphql`
		query {
			flower: file(relativePath: { eq: "flower.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			shadow: file(relativePath: { eq: "flower-shadow.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			garden: file(relativePath: { eq: "logo-gardenoffrida.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			corpo: file(relativePath: { eq: "logo-kahlocorporation.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			corporound: file(relativePath: { eq: "logo-kahlocorporation-round.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const flower = data.flower?.childImageSharp.fluid
	const shadow = data.shadow?.childImageSharp.fluid
	const garden = data.garden?.childImageSharp.fluid
	const corpo = data.corpo?.childImageSharp.fluid
	const corporound = data.corporound?.childImageSharp.fluid

  return (
		<footer id='master-footer' className='c4 is-inview grid-12'>

			{ flower ?
				<>
					<div className="flower-1-positioner">
						<div className="animated from-right">
							<Img fluid={flower}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								className="flower-1"
								loading='eager'
							/>
						</div>
					</div>
					<div className="flower-2-positioner">
						<div className="animated from-right">
							<Img fluid={flower}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								className="flower-2"
								loading='eager'
							/>
						</div>
					</div>
				</>
			: null }

			<div className="content-box">
				<h2>“At the end of the day, we can endure<br/>much more than we think we can.“<br/><span className="text-right">- Frida</span></h2>
			</div>

			<div className='footer-icons'>
				<a href='https://www.facebook.com/FridaKahloCorporation' className='hint--rounded hint--top' data-hint='FaceBook' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faFacebook} /></a>
				<a href='https://instagram.com/realFridaKahlo' className='hint--rounded hint--top' data-hint='Instagram' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faInstagram} /></a>
				<a href='https://airbnb.com/FridaKahloCorporation' className='hint--rounded hint--top' data-hint='AirBnB' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faAirbnb} /></a>
			</div>

			<div className="logos">
				<a className="logo-garden" href="https://www.gardenoffrida.com">
					<Img fluid={garden}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="garden"
						loading='eager'
					/>
				</a>
				<div className="logo-corpo">
					<Img fluid={corpo}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="corpo"
						loading='eager'
					/>
				</div>
				<div className="logo-corporound">
					<Img fluid={corporound}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="corporound"
						loading='eager'
					/>
				</div>
			</div>

			<div className="become-airbnb">
				<p><a href="mailto:info@fridakahlocorporation.com" className="btn" target="_blank" rel="noref" onClick={(e) => { e.preventDefault(); modalContext.setActive(true) }}>Become an official Airbnb</a></p>
			</div>

		
			<div className='copy'>
				<p>Copyright &copy; 2020 Frida Kahlo Corporation. Made with <FontAwesomeIcon icon={faHeart} /> by <a className='hint--rounded hint--top' data-hint='Much love!' href="https://www.deepsleepstudio.com" target="_blank" rel="noref">ds/s</a>.</p>
				<p>All Information contained on FridaKahloCorporation.com is for general information purposes only. <br/>All information provided is deemed reliable but not guaranteed.</p>
			</div>


			<div className="flower-shadow">
				<Img fluid={shadow}
					imgStyle={{objectFit: 'contain'}}
					objectPosition='50% 50%'
					className="flower"
					loading='eager'
				/>
			</div>

			<Modal>
				<h3 className="text-center">Become an official licensee:</h3>
				<CF7RequestQuote />
			</Modal>

		</footer>
  )
}

export default Footer