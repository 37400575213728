import React, {useState, useEffect} from 'react'
import Img from 'gatsby-image'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Gallery(props) {
	const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
	const [slider2, setSlider2] = useState(null)
	
	useEffect(() => {

    setNav1(slider1)
    setNav2(slider2)

  })

	const slides = props.slider
	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 1000,
		pauseOnFocus: true,
		autoplay: false,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		accessibility: false,
		fade: false,
		focusOnSelect: true,
		adaptiveHeight: false,
		centerMode: false,
		variableWidth: false,
		asNavFor: '.slider-nav'
	}
	const settings2 = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 1000,
		pauseOnFocus: true,
		autoplay: false,
		autoplaySpeed: 8000,
		slidesToShow: 4,
		slidesToScroll: 1,
		accessibility: false,
		fade: false,
		focusOnSelect: true,
		adaptiveHeight: false,
		centerMode: false,
		variableWidth: false,
		asNavFor: '.slider-for',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
		]
	}

	const SlidesPack = slides.map((slide, i) => {

		const image = slide.img?.localFile.childImageSharp.fluid

		return (
			<div key={`slide-${i}`} className="slide">
				{ image ?
					<>
						<Img fluid={image}
							loading='lazy'
							fadeIn={true}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
						/>
						<div className='content-positioner' dangerouslySetInnerHTML={{__html: slide.wysiwyg}} />
					</>
				: null }
			</div>
		)
	})

	const SlidesPack2 = slides.map((slide, i) => {

		const image = slide.img?.localFile.childImageSharp.fluid

		return (
			<div key={`slide-${i}`} className="slide thumb">
				{ image ?
					<>
						<Img fluid={image}
							loading='lazy'
							fadeIn={true}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
						/>
					</>
				: null }
			</div>
		)
	})

	return (
		<>
			<Slider {...settings}
				asNavFor={nav2}
				ref={slider => (setSlider1(slider))}
			>
				{SlidesPack}
			</Slider>
			<div className="thumbstrip grid-12">
				<h2>The Room</h2>
				<Slider {...settings2}
					asNavFor={nav1}
					ref={slider => (setSlider2(slider))}
				>
					{SlidesPack2}
				</Slider>
			</div>
		</>
  )
}

export default Gallery