import React from 'react'
import './style.scss'

import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import Carousel from '../../../Slider'
import Gallery from '../../../Gallery'

function Slider(props) {
	const content = props.slider
	const image = props.img?.localFile.childImageSharp.fluid

	const data = useStaticQuery(graphql`
		query {
			corp: file(relativePath: { eq: "logo-kahlocorporation.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			casa: file(relativePath: { eq: "logo-casakahlo.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			transition: file(relativePath: { eq: "transition-green-bottom.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			flower: file(relativePath: { eq: "flower.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			shadow: file(relativePath: { eq: "flower-shadow.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const casa = data.casa?.childImageSharp.fluid
	const corp = data.corp?.childImageSharp.fluid
	const flower = data.flower?.childImageSharp.fluid
	const shadow = data.shadow?.childImageSharp.fluid

  return (
		<>
			{ content && props.section_id === 'kahlo-casa' ? 
				<section id={props.section_id} className={`content c4 is-inview grid-12 ${props.classes}`}>

					{ props.anchor ?
						<div className="dss-anchor" id={props.anchor}></div>
					: null }

					<div className="image-frida">
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
							/>
						: null }
					</div>

					<div className='content-box'>
						<div className="logo-kahlo-casa">
							<Img fluid={casa}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								loading='eager'
							/>
						</div>

						<div className='content-holder gallery-center animated'>
							<Carousel {...props} />
						</div>

						<div className="logo-kahlo-corporation">
							<Img fluid={corp}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								loading='eager'
							/>
						</div>
					</div>

					{ flower ?
						<>
							<div className="flower-3-positioner inview">
								<Img fluid={flower}
									imgStyle={{objectFit: 'contain'}}
									objectPosition='50% 50%'
									className="flower-3"
									loading='eager'
								/>
							</div>
							<div className="flower-4-positioner">
								<Img fluid={flower}
									imgStyle={{objectFit: 'contain'}}
									objectPosition='50% 50%'
									className="flower-4"
									loading='eager'
								/>
							</div>
						</>
					: null }

					<div className="flower-shadow">
						<Img fluid={shadow}
							imgStyle={{objectFit: 'contain'}}
							objectPosition='50% 50%'
							className="flower"
							loading='eager'
						/>
					</div>

				</section>
			: content && props.section_id === 'kahlo-gallery' ?
				<section id={props.section_id} className={`content c4 is-inview grid-12 ${props.classes}`}>
					{ props.anchor ?
						<div className="dss-anchor" id={props.anchor}></div>
					: null }
					<div className='span-12 grid-12 gallery-center animated'>
						<Gallery {...props} />
					</div>
				</section>
			: null }
		</>
  )
}

export default Slider