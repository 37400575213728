import React from 'react'
import './style.scss'

import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import VideoComponent from '../../../Video/HTML'

function Video(props) {

	const data = useStaticQuery(graphql`
		query {
			flower: file(relativePath: { eq: "flower.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			corp: file(relativePath: { eq: "logo-kahlocorporation.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			transition: file(relativePath: { eq: "transition-green.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const content = props.video
	const image = props.img?.localFile.childImageSharp.fluid
	const flower = data.flower?.childImageSharp.fluid
	const corp = data.corp?.childImageSharp.fluid
	const transition = data.transition?.childImageSharp.fluid

  return (
		<>
			{ content ? 
			<section id={props.section_id} className={`c4 grid-12 is-inview ${props.classes}`}>
				{ props.anchor ?
					<div className="dss-anchor" id={props.anchor}></div>
				: null }
				{ flower ?
					<>
						<div className="flower-1-positioner animated">
							<Img fluid={flower}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								className="flower-1"
								loading='eager'
							/>
						</div>
						<div className="flower-2-positioner animated">
							<Img fluid={flower}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								className="flower-2"
								loading='eager'
							/>
						</div>
					</>
				: null }

				<div className="video-wrap animated">
					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							className="bg"
							loading='eager'
						/>
					: null }
					<div className="video-wrap-border">
						<VideoComponent file="frida.mp4"/>
					</div>
				</div>

				<div className="logo-kahlo-corporation">
					<Img fluid={corp}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='eager'
					/>
				</div>

				<div className="transition">
					<Img fluid={transition}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						className="transition-green"
						loading='eager'
					/>
				</div>

			</section>
			: null }
		</>
  )
}

export default Video